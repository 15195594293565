import * as React from "react"
import "../styles/pages/404.scss"


const NotFoundPage = () => {
    return (
        <main>
            <title>Not found</title>
            <h1>Page not found</h1>
        </main>
    )
}

export default NotFoundPage
